<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list dense>
        <v-list-item link v-for="item in orderedMenu " :key="item.id" :to="item.url">
          <v-list-item-content>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click='logout'>
         <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Kijelentkezés</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="blue lighten-1"
      light
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title><strong class="white--text">FROSTOX</strong></v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
          >
            <span>{{ user.name }}</span>
            <v-icon class="pl-4">mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link @click='logout'>
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Kijelentkezés</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>     
    </v-app-bar>

    <v-main class="blue lighten-4">
      <v-container
        fluid
      >
        <router-view></router-view>
      </v-container>
    </v-main>
    <loader :isLoading="isLoading"></loader> 
  </v-app>
</template>
<script>
    export default {

      props: {
        source: String,
        menu: null
      },

      data: () => ({
        drawer: false,
      }),

      mounted() {
        this.$store.dispatch('user/getUser')
      },

      computed: {
        user() {
          return this.$store.getters['user/user'];
        },
        isLoading () {
          return this.$store.state.loadingStatus
        },
        orderedMenu() {
          return _.orderBy(this.menu, 'order')
        }
      },

      methods: {
          logout() {
            axios.post('/logout')
              .then(response => {
                window.location.href = 'login'
              })
          }
      } 
      
    }
</script>